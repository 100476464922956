<script>
import ServersSideBar from "@/components/sidebar/servers-sidebar.vue";
import BotSideBar from "@/components/sidebar/bot-sidebar.vue";
import NavBar from "@/components//navbar/navbar.vue";

export default {
    components: {
        NavBar,
        ServersSideBar,
        BotSideBar,
    },
    data() {
        return {
            lang: {
                iso: this.$store.state.lang,
                translation: this.$translation,
            },
            assets: {
                shop: require("@/assets/image/shop.png"),
            },
            subscribe: null,
        };
    },
    computed: {
        info() {
            return this.$store.state.info;
        },
    },
    methods: {
        Translate(name) {
            var ctx = this;
            if (ctx.lang.translation[ctx.lang.iso][name])
                return ctx.lang.translation[ctx.lang.iso][name];
            else return "None";
        },
    },
    async mounted() {
        var ctx = this;
        document.title = "DistroyMe | Shop";
        await ctx.me();

        ctx.subscribe = ctx.$store.subscribe((mutation) => {
            if (mutation.type == "lang") {
                ctx.lang.iso = mutation.payload;
            }
        });
    },
    beforeUnmount() {
        this.subscribe();
    },
};
</script>

<style>
@import url("../assets/scss/shop/main.css");
</style>

<template>
    <div>
        <ServersSideBar></ServersSideBar>
        <BotSideBar></BotSideBar>
        <NavBar></NavBar>
        <div class="shop">
            <div class="title">
                <hr />
                <div class="box">
                    <img class="icone" :src="assets.shop" />
                    <div class="big-title">{{Translate("store")}}</div>
                </div>
                <hr />
            </div>
            <div class="list">
                <div class="card">
                    <div class="title">{{Translate("pack_premium_title")}}</div>
                    <div class="content">{{Translate("pack_premium_text")}}</div>
                    <div class="call_to_action">
                        <div v-if="info.status == 0" class="sub-text">
                            {{Translate("buy")}}
                        </div>
                        <div v-if="info.status >= 1" class="sub-text">
                            {{Translate("already_buy")}}
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="title">{{Translate("pack_bot_title")}}</div>
                    <div class="content">{{Translate("pack_bot_text")}}</div>
                    <div class="call_to_action">
                        <div v-if="info.status >= 1" class="sub-text">
                            {{Translate("buy")}}
                        </div>
                        <div
                            v-if="info.status == 0"
                            class="sub-text"
                            style="cursor: not-allowed"
                        >
                            {{Translate("already_buy")}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>